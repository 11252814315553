html,
body,
#root {
  height: 100%;
  background-color: #E4DCDB;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(14px + 0.390625vw);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
}

footer {
  position: fixed;
  background-color: #E4DCDB;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
  color: #6435c9;
  justify-content: center;
  box-shadow: none;
}

a {
  display: inline-block;
}

.image-link {
  padding-top: 0px;
  height: auto;
  color: #6435c9;
}
.all_bubble_container {
  bottom: 0;
  height: calc(60% - 3rem);
overflow-y: scroll;
padding: 1rem;
padding-bottom: 0.5rem;
}
.bubble_container {
  width: 100%;
}
