.App {
  text-align: center;
  min-height: 100vh;
}

footer {
  position: fixed;
  background-color: #E4DCDB;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
  color: #6435c9;
  justify-content: center;
  box-shadow: none;
}

a {
  display: inline-block;
}

.image-link {
  padding-top: 0px;
  height: auto;
  color: #6435c9;
}